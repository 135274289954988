import React from 'react';
import { ReactComponent as HhLogo } from './hh-logo.svg';
import { ReactComponent as NikeLogo } from './nike-logo.svg';

var divStyle = {
  width: "1080px",
  height: "1920px",
  overflow: "hidden",
}

class Person extends React.Component {
  render() {
    return <div class="flex border-gray-100">
    <div className={"w-1/6 border-r-4 border-gray-100 text-center" + (this.props.position === 1 ? ' pt-12' : ' pt-10') + (this.props.position === this.props.length ? ' pb-12' : '')}>
      <h2 class="text-4b-xl font-montserrat font-black uppercase">
        {this.props.position}.
      </h2>
    </div>
    <div className={"w-4/6 px-12 border-r-4 border-gray-100" + (this.props.position === 1 ? ' pt-12' : ' pt-10') + (this.props.position === this.props.length ? ' pb-12' : '')}>
      <h2 class="text-4b-xl font-montserrat font-black uppercase truncate">
        {this.props.name}
      </h2>
    </div>
    <div className={"w-1/6 text-center" + (this.props.position === 1 ? ' pt-12' : ' pt-10') + (this.props.position === this.props.length ? ' pb-12' : '')}>
      <h2 class="text-4b-xl text-hhyellow font-montserrat font-black uppercase">
        {this.props.score}
      </h2>
    </div>
  </div>
  }
}

class App extends React.Component {
  state = {
    people: [],
    score: 0,
    raised: 0,
    length: 0
  }

  getTopTen() {
    var Airtable = require('airtable');
    var base = new Airtable({apiKey: 'key6Tigoyr6fyc4sK'}).base('appXbXYj7q0cVbJ7E');
  
    return base('Participants').select({
      filterByFormula: 'AND({Shots Made}>1,{Top 10 Exclude}=FALSE())',
      maxRecords: 10,
      fields: ["First Name", "Last Name", "Shots Made"],
      sort: [{
        field: 'Shots Made',
        direction: 'desc'
      }]
    }).all()
    .then(records => {
      this.setState({ people: records});
      this.setState({ length: records.length})
    }).catch(err => {
      console.error(err); return;
    });
  }

  getTotals() {
    var Airtable = require('airtable');
    var base = new Airtable({apiKey: 'key6Tigoyr6fyc4sK'}).base('appXbXYj7q0cVbJ7E');
  
    return base('Totals').select({ 
      fields: ["Shots Made","Raised"],
    }).all()
    .then(records => {
      this.setState({ score: records[0].fields["Shots Made"]});
      this.setState({ raised: records[0].fields["Raised"]});
    }).catch(err => {
      console.error(err); return;
    });
  }

  updateFunctions() {
    this.getTopTen();
    this.getTotals();
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  componentWillMount() {
    this.updateFunctions();
  }

  componentDidMount() {
    this.interval = setInterval(async () => this.updateFunctions(), 15000 );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {

    const peopleCards = this.state.people.map((item, key) =>
        <Person key={item.id} position={key+1} length={this.state.length} name={`${item.fields["First Name"]} ${item.fields["Last Name"]}`} score={item.fields["Shots Made"]} />
    );

    return <div class="App bg-black text-gray-100 p-10 mx-auto" style={divStyle}>

      {/* Logo & Titles */}
      <div className="pb-10 flex justify-between items-center">
        <div className="flex items-center">
          <HhLogo class="h-72"/>
          <NikeLogo class="ml-8 h-12"/>
        </div>
        <div className="text-right">
          <h2 class="text-5a-xl leading-tight text-hhorange font-montserrat font-black uppercase">
            Leaderboard
          </h2>
          <h3 class="text-4a-xl leading-none font-montserrat font-black">
            #charityshootout
          </h3>
        </div>
      </div>

      {/* Totals section */}
      <div class="flex border-4 border-gray-100">
        <div class="w-1/2 px-12 py-8 border-r-4 border-gray-100">
          <h2 class="text-3xl font-montserrat font-black uppercase">
            Total Shots Made
          </h2>
          <h3 class="text-7xl text-hhorange font-montserrat font-black uppercase">
            {this.numberWithCommas(this.state.score)}
          </h3>
        </div>
        <div class="w-1/2 px-12 py-8">
          <h2 class="text-3xl font-montserrat font-black uppercase">
            Total Raised
          </h2>
          <h3 class="text-7xl text-hhorange font-montserrat font-black uppercase">
            ${this.numberWithCommas(this.state.raised)}
          </h3>
        </div>
      </div>

      {/* Table Headings */}
      <div class="flex border-r-4 border-b-4 border-l-4 border-gray-100">
        <div class="w-1/6 py-8 border-r-4 border-gray-100 text-center">
          <h2 class="text-3xl font-montserrat font-black uppercase">
            Rank
          </h2>
        </div>
        <div class="w-4/6 px-12 py-8 border-r-4 border-gray-100">
          <h2 class="text-3xl font-montserrat font-black uppercase">
            Name
          </h2>
        </div>
        <div class="w-1/6 py-8 text-center">
          <h2 class="text-3xl font-montserrat font-black uppercase">
            Score
          </h2>
        </div>
      </div>

      {/* Top Scorers */}
      <div class="border-r-4 border-b-4 border-l-4 border-gray-100">
        {peopleCards}
      </div>

    </div>
  }
}

export default App;
